import * as React from 'react';
import {useEffect, useState} from 'react';
import { GoogleOAuthProvider, GoogleLogin } from "@react-oauth/google";

import { useAuth } from '../AuthContext';

function Login() {
  const { getAuthUser } = useAuth();

  useEffect(() => {
    document.title = 'FeelingHealth - Heart Login';
  }, []);

  const Content = () => {

    const SignIn = () => {
      const [liveEmailText, setLiveEmailText] = useState(process.env.REACT_APP_AUTH_USER || '');
      const [livePassText, setLivePassText] = useState(process.env.REACT_APP_AUTH_USER_PASS || '');

      const signIn = async () => {
        console.log('signIn');
        try {
          const response = await fetch(`${process.env.REACT_APP_BRAIN_HOST}/signIn`, {
            method: 'POST',
            credentials:'include',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify({
              email: liveEmailText,
              password: livePassText,
            }),
          });

          if (!response.ok) {
            console.error('Error signing in:', response.statusText);
            return;
          }

          await getAuthUser();

          // console.log('Sign-in successful:', response);

        } catch (error) {
          console.error('Error signing in:', error);
        }
      };

      const signInWithGoogle = async (credentialResponse) => {
        console.log('signInWithGoogle', credentialResponse);

        try {
          const response = await fetch(`${process.env.REACT_APP_BRAIN_HOST}/signIn`, {
            method: 'POST',
            credentials:'include',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify({
              googleCredential: credentialResponse,
            }),
          });

          if (!response.ok) {
            console.error('Error signing in:', response.statusText);
            return;
          }

          await getAuthUser();

          // console.log('Sign-in successful:', response);

        } catch (error) {
          console.error('Error signing in:', error);
        }
      };

      const LoginWithEmailPass = () => {
        if (process.env.REACT_APP_ENV === 'production') {
          return <div />;
        }

        return (
          <div style={{marginBottom: "15px"}}>
            <div style={{ marginBottom: "15px" }}>
              <label style={{ display: "block", marginBottom: "5px", fontWeight: "bold" }}>
                Email
              </label>
              <input
                type="text"
                value={liveEmailText}
                onChange={e => setLiveEmailText(e.target.value)}
                style={{
                  width: "90%",
                  padding: "10px",
                  border: "1px solid #ccc",
                  borderRadius: "4px",
                  fontSize: "16px",
                }}
              />
            </div>
            <div style={{ marginBottom: "15px" }}>
              <label style={{ display: "block", marginBottom: "5px", fontWeight: "bold" }}>
                Password
              </label>
              <input
                type="password"
                value={livePassText}
                onChange={e => setLivePassText(e.target.value)}
                style={{
                  width: "90%",
                  padding: "10px",
                  border: "1px solid #ccc",
                  borderRadius: "4px",
                  fontSize: "16px",
                }}
              />
            </div>
            <button
              onClick={signIn}
              style={{
                width: "100%",
                padding: "10px",
                backgroundColor: "#007BFF",
                color: "#fff",
                border: "none",
                borderRadius: "4px",
                fontSize: "16px",
                cursor: "pointer",
              }}
            >
              Sign In
            </button>
          </div>
        );
      };

      const LoginWithGoogle = () => {
        return (
          <div style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}>
            <GoogleOAuthProvider clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID}>
              <GoogleLogin
                onSuccess={signInWithGoogle}
                onError={() => console.error("Google login failed")}
              />
            </GoogleOAuthProvider>
          </div>
        );
      };

      return (
        <div style={{
          maxWidth: "400px",
          margin: "0 auto",
          padding: "20px",
          border: "1px solid #ddd",
          borderRadius: "8px",
          boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
          fontFamily: "Arial, sans-serif",
        }}>
          <h2 style={{
            textAlign: "center",
            marginBottom: "20px",
            fontSize: "24px",
            color: "#333",
          }}>
            Login
          </h2>
          <LoginWithEmailPass />
          <LoginWithGoogle />
        </div>
      )

    };

    return (
      <div>
        <SignIn />
      </div>
    );
  };


  return (
    <div className="App">
      <Content />
    </div>
  );
}

export default Login;
