

// Date
//////////////////

/**
 * Describe the date using plain text
 *
 * @param date
 * @param params {localeDateParams}
 */
export const getPlainTextDate = (date, params = {}) => {
    params = {
        localeDateParams: null,
        ...params,
    };

    let dateText = '';
    if (params.localeDateParams) {
        dateText = date.toLocaleDateString('en-US', params.localeDateParams);
    } else {
        dateText = date.toDateString();
    }

    return dateText;
};


// Time
//////////////////

/**
 * Get the standard way to show the time
 */
export const getPlainTextTime = (date, showSeconds = false) => {

    let hours = date.getHours();
    let min = date.getMinutes();
    let seconds = date.getSeconds();
    const ampm = hours >= 12 ? 'pm' : 'am';
    hours = (hours === 0 || hours === 12) ? 12 : hours % 12;
    min = min < 10 ? '0' + min : min;

    return hours + ':' + min + (showSeconds ? ':' + seconds : '') + ampm;
};