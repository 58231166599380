import React from 'react';
import ReactDOM from 'react-dom/client';
import { createBrowserRouter, RouterProvider, Navigate, Outlet } from 'react-router-dom';

import { AuthProvider, useAuth } from './AuthContext';
import Header from './components/Header';
import './index.css';

import Login from './routes/Login';
import ErrorPage from './routes/ErrorPage';
import Dashboard from './routes/Dashboard';
import Search from './routes/Search';
import User from './routes/User';
import Template from './routes/Template';

const ProtectedRoute = () => {
    const { authUser } = useAuth();
    // console.log('ProtectedRoute', authUser);
    return authUser ? <Outlet /> : <Navigate to="/login" replace />;
};

const GuestRoute = ({ children }) => {
    const { authUser } = useAuth();
    return authUser ?  <Navigate to="/" /> : children;
};

const AppLayout = ({ children }) => {
    // console.log('AppLayout');
    return (
      <div>
          <Header />
          <main style={{ padding: '20px' }}>
              {children || <Outlet />}
          </main>
      </div>
    );
};

const router = createBrowserRouter([
    {
        path: "/",
        element: <ProtectedRoute />,
        children: [
            {
                path: '/',
                element: <AppLayout />,
                children: [
                    {
                        index: true,
                        element: <Dashboard />,
                    },
                    {
                        path: "search",
                        element: <Search />,
                    },
                    {
                        path: "user/:userId",
                        element: <User />,
                    },
                    {
                        path: "user/:userId/template/:entryTemplateId",
                        element: <Template />,
                    },
                ]
            },
        ],
        errorElement: <ErrorPage />,
    },
    {
        path: "/login",
        element: (
          <GuestRoute>
              <AppLayout>
                <Login />
              </AppLayout>
          </GuestRoute>
        ),
    },
]);

ReactDOM.createRoot(document.getElementById('root')).render(
  <AuthProvider>
    <RouterProvider router={router} />
  </AuthProvider>
);
