import React, {createContext, useState, useEffect, useContext} from 'react';
import Cookies from 'js-cookie';

export const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [authUser, setAuthUser] = useState(JSON.parse(Cookies.get('auth_user') || null));

  useEffect(() => {
    getAuthUser().then();
  }, []);

  const getAuthUser = async () => {
    console.log('getAuthUser');

    try {
      const response = await fetch(process.env.REACT_APP_BRAIN_HOST + '/getAuthUser', {
        credentials: "include",
      });

      if (!response.ok) {
        // User is not logged in
        setAuthUser(null);
        return;
      }

      const myUser = await response.json();
      // console.log('got auth user', myUser);

      Cookies.set('auth_user', JSON.stringify(myUser), { path: '/' });
      setAuthUser(myUser);

      // Refresh the auth token in 30min if the user hasn't refreshed the page
      setTimeout(getAuthUser, 1000 * 60 * 30);

    } catch (error) {
      console.log('Error getting auth user', error);
      setAuthUser(null);
    }

  };

  return (
    <AuthContext.Provider value={{ authUser, setAuthUser, getAuthUser }}>
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => useContext(AuthContext);
