import React from 'react';
import { useAuth } from '../AuthContext';
import Cookies from "js-cookie";

const Header = () => {
  const { authUser, setAuthUser } = useAuth();

  const signOut = async () => {
    console.log('signOut');
    try {
      const response = await fetch(`${process.env.REACT_APP_BRAIN_HOST}/signOut`, {
        method: 'POST',
        credentials:'include'
      });

      if (!response.ok) {
        console.error('Error signing out:', response.statusText);
        return;
      }

      console.log('Sign-out successful:', response);
      Cookies.remove('auth_user');
      setAuthUser(null);
    } catch (error) {
      console.error('Error signing out:', error);
    }
  };

  return (
    <header style={styles.header}>
      <h1 style={styles.title}>FeelingHealth Heart</h1>
      {authUser && (
        <button style={styles.signOutButton} onClick={signOut}>
          Sign Out
        </button>
      )}
    </header>
  );
};

const styles = {
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '10px 20px',
    backgroundColor: '#f5f5f5',
    borderBottom: '1px solid #ddd',
  },
  title: {
    margin: 0,
  },
  signOutButton: {
    backgroundColor: '#007BFF',
    color: '#fff',
    border: 'none',
    padding: '5px 10px',
    borderRadius: '5px',
    cursor: 'pointer',
  },
};

export default Header;
