import * as React from 'react';

function ErrorPage() {

    return (
        <div>
            <span>Error! Page not found...</span>
        </div>
    );
}

export default ErrorPage;
