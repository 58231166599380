import * as React from 'react';
import {Fragment, useEffect, useState} from 'react';
import {Link, useNavigate} from 'react-router-dom';

import * as DateTime from '../utilities/dateTime';

import '../assets/styles/Dashboard.css';
import {base64ToGuid} from "../utilities/core";

function Dashboard() {
    const [users, setUsers] = useState(null);
    const [stats, setStats] = useState(null);

    const navigate = useNavigate();

    useEffect(() => {
        getUsers().then();
        getStats().then();
        document.title = 'FeelingHealth - Heart Dashboard';
    }, []);

    const getTest = () => {
        fetch(process.env.REACT_APP_BRAIN_HOST + '/test', {
          credentials: "include",
        })
            .then(response => response.json())
            .then(
                result => {
                    console.log('getTest Results', result);
                },
                error => {
                    console.log('getTest Error', error);
                }
            );
    };

    const getUsers = async () => {
      const response = await fetch(process.env.REACT_APP_BRAIN_HOST + '/user',{
        credentials: "include",
      });
      console.log('getUsers response', response);
      // console.log('response body', await response.json());

      if (!response.ok) {
        console.error('Error getting users:', response.statusText);
        return;
      }

      const myUsers = await response.json();
      setUsers(myUsers);
    };

    const getStats = async () => {

      const response = await fetch(process.env.REACT_APP_BRAIN_HOST + '/stats/totals',{
        credentials: "include",
      });
      // console.log('getStats response', response);

      if (!response.ok) {
        console.error('Error getting stats:', response.statusText);
        return;
      }

      const myStats = await response.json();
      setStats(myStats);
    };

    const Content = () => {

        const Search = () => {
            const [liveSearchText, setLiveSearchText] = useState('');

            const navigateToSearch = event => {
                if (liveSearchText.length < 2) {
                    return;
                }

                navigate('/search/' +  liveSearchText);
            }

            return (
                <div>
                    Search <input type={'text'}
                                  value={liveSearchText}
                                  onChange={e => setLiveSearchText(e.target.value)}
                                  onKeyUp={e => {
                                      if (e.key === 'Enter') {
                                          navigateToSearch()
                                      }
                                  }}/>
                </div>
            );
        };

        const Stats = () => {

            if (!stats) {
              return <Fragment />;
            }

            return (
                <div>
                  <div style={{marginTop: '20px', fontSize: '26px'}}>Overall Stats</div>
                  <div>{stats.numUsers} users</div>
                  <div>{stats.numEntries} total entries</div>
                  <div>{stats.numEntryTemplates} total templates</div>
                  <div>{stats.numEntryTags} total tags</div>
                </div>
            );
        };

        const Users = () => {
            let myUsers = [...users];
            myUsers = myUsers.sort((a, b) => {
                return new Date(b.last_active_date) - new Date(a.last_active_date);
            });
            return (
                <div>
                  <div style={{marginTop: '20px', fontSize: '26px'}}>Users</div>
                  <div style={{width: '600px', margin: 'auto'}}>
                    <span style={{width: '350px'}}>User ID</span>
                    <span style={{marginLeft: '200px'}}>Last Active On</span>
                  </div>
                    {myUsers.map((user, key) => {
                        const lastActiveDate = new Date(user.last_active_date);
                        return (
                            <div key={key} style={{width: '600px', margin: 'auto', marginTop: '5px'}}>
                                <div style={{width: '350px', float: 'left'}}>
                                    <Link to={`user/` + user._id}>{user._id}</Link>
                                </div>
                                <div style={{textAlign: 'left'}}>
                                    {DateTime.getPlainTextDate(lastActiveDate)}
                                    {' '}at {DateTime.getPlainTextTime(lastActiveDate)}
                                </div>
                            </div>
                        );
                    })}
                </div>
            );
        };

        return (
            <div>
                <Search />
                <div onClick={getTest}>Get Test</div>
                {stats ? <Stats /> : null}
                {users ? <Users /> : null}
            </div>
        );
    };


    return (
        <div className="App">
            <Content />
        </div>
    );
}

export default Dashboard;
