import {Buffer} from "buffer";

export const base64ToGuid = (base64Val, withHyphens = true) => {

    let myGuid = Buffer.from(base64Val, 'base64').toString('hex');

    if (!withHyphens) {
        return myGuid;
    }

    // Add the hyphens
    return addHyphensToGuid(myGuid);
}

export const addHyphensToGuid = myGuid => {
    return myGuid.substr(0,8) + "-" + myGuid.substr(8,4)
        + "-" + myGuid.substr(12,4) + "-" + myGuid.substr(16,4)
        + "-" + myGuid.substr(20);
}
