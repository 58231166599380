import * as React from 'react';
import {useEffect, useState} from 'react';
import {Link, useParams} from "react-router-dom";


function Search() {
    const params = useParams()

    const [searchResults, setSearchResults] = useState(null);

    useEffect(() => {
        doSearch(params.searchText);
    }, []);

    const doSearch = searchText => {
        console.log('doSearch', searchText);

        if (searchText?.length < 3) {
            return;
        }

        fetch(process.env.REACT_APP_BRAIN_HOST + '/search?searchText=' + searchText,
            {headers: new Headers({
                    'Heart-Secret-Key': process.env.REACT_APP_HEART_SECRET_KEY,
                })}
        )
            .then(response => response.json())
            .then(
                result => {
                    console.log('search r', result);
                    setSearchResults(result);
                },
                error => {
                    console.log('search e', error);
                }
            );
    }

    const Content = () => {

        const SearchBar = () => {
            const [liveSearchText, setLiveSearchText] = useState(params.searchText);

            return (
                <div>
                    Search: <input type={'text'}
                                   value={liveSearchText}
                                   onChange={e => setLiveSearchText(e.target.value)}
                                   onKeyUp={event => {
                                       if (event.key !== 'Enter') {
                                           return;
                                       }
                                       doSearch(liveSearchText);
                                   }}/> {}
                </div>
            );
        };

        const SearchResults = () => {

            const EntryResults = () => {

                return (
                    <div>
                        <h2>Entry Results ({searchResults.entries.length})</h2>
                    </div>
                );
            };

            const TemplateResults = () => {

                return (
                    <div>
                        <h2>Template Results ({searchResults.templates.length})</h2>
                    </div>
                );
            };

            const TagResults = () => {

                return (
                    <div>
                        <h2>Tag Results ({searchResults.tags.length})</h2>
                    </div>
                );
            };

            return (
                <div>
                    {searchResults.entries.length ? <EntryResults /> : null}
                    {searchResults.templates.length ? <TemplateResults /> : null}
                    {searchResults.tags.length ? <TagResults /> : null}
                </div>
            )
        }

        return (
            <div>
                <SearchBar />
                {searchResults ? <SearchResults /> : null}
            </div>
        );
    };


    return (
        <div style={{padding: '20px'}}>
            <div>
                <Link to={`/`}>Back to Dashboard</Link>
            </div>
            <Content />
        </div>
    );
}


export default Search;
