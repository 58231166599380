import * as React from 'react';
import {Link, useParams} from "react-router-dom";
import {useEffect, useRef, useState} from "react";
import { IoMdClose } from 'react-icons/io';

import { base64ToGuid } from '../utilities/core';
import * as DateTime from '../utilities/dateTime';

function User() {
    const params = useParams()
    const [user, setUser] = useState(null);
    const [entryTemplates, setEntryTemplates] = useState([]);
    const [entryTags, setEntryTags] = useState([]);
    const [viewAllObjects, setViewAllObjects] = useState(false);

    const [mlTemplates, setMlTemplates] = useState([]);
    const [processing, setProcessing] = useState(false);
    const [downloadCSV, setDownloadCSV] = useState(true);
    const [periodLength, setPeriodLength] = useState(360);

    const numDays = useRef(365);

    useEffect(() => {
      getUser().then();
      document.title = 'FeelingHealth - User';
    }, []);

    useEffect(() => {
      // getEntryTemplates();
      // getEntryTags();
    }, [user])


    const getUser = async () => {
      // console.log('getUser');

      const response = await fetch(process.env.REACT_APP_BRAIN_HOST + '/user/' + params.userId,{
        credentials: "include",
      });
      // console.log('getUser response', response);
      // console.log('response body', await response.json());

      if (!response.ok) {
        console.error('Error getting user:', response.statusText);
        return;
      }

      const myUser = await response.json();
      setUser(myUser);
    };

    const getEntryTemplates = () => {
        console.log('getEntryTemplates');

        if (!user) {
            return;
        }

        fetch(process.env.REACT_APP_BRAIN_HOST + '/entryTemplates?_partition=' + user._partition,
            {headers: new Headers({
                    'Heart-Secret-Key': process.env.REACT_APP_HEART_SECRET_KEY,
                })}
        )
            .then(response => response.json())
            .then(
                result => {
                    console.log('templates r', result);
                    setEntryTemplates(result);
                },
                error => {
                    console.log('templates e', error);
                }
            );
    };

    const getEntryTags = () => {
        console.log('getEntryTags');

        if (!user) {
            return;
        }

        fetch(process.env.REACT_APP_BRAIN_HOST + '/entryTags?_partition=' + user._partition,
            {headers: new Headers({
                    'Heart-Secret-Key': process.env.REACT_APP_HEART_SECRET_KEY,
                })}
        )
            .then(response => response.json())
            .then(
                result => {
                    console.log('tags r', result);
                    setEntryTags(result);
                },
                error => {
                    console.log('tags e', error);
                }
            );
    };

    const Content = () => {

        const AppInfo = () => {

            if (!user) {
                return <span />
            }
            const [dbSchema] = user.settings.filter(setting => setting.name === 'DB:realmSchema');
            const [buildInfo] = user.settings.filter(setting => setting.name === 'APP:buildInfo');

            return (
                <div>
                    <h4 style={{marginBottom: '5px'}}>App Info</h4>
                    <div>DB Schema Version: {dbSchema.value.version}</div>
                    <div>Build Date: {buildInfo.value.buildDate}</div>
                </div>
            );
        };

        const ProcessInsights = () => {
            const [responseText, setResponseText] = useState('');
            const [liveNumDays, setLiveNumDays] = useState(numDays.current);

            const processInsights = () => {
                console.log('processInsights');

                if (mlTemplates.length < 1) {
                    setResponseText('Please choose at least one template');
                    return;
                }

                const templateIds = mlTemplates.reduce((prev, template) => {
                    return (prev.length ? prev + ',' : '') + base64ToGuid(template._id);
                }, '');

                setProcessing(true);

                // Download the CSV
                fetch(process.env.REACT_APP_BRAIN_HOST + '/insights/timebased/process?' +
                    '_partition=' + user._partition + '&templateIds=' + templateIds +
                    '&downloadCSV=' + downloadCSV +
                    '&periodLength=' + periodLength +
                    '&numDays=' + numDays.current,
                    {
                        headers: new Headers({
                            'Heart-Secret-Key': process.env.REACT_APP_HEART_SECRET_KEY,
                            'Access-Control-Expose-Headers': 'Authorization',
                        })
                    }
                )
                    .then(response => {
                        if (!response.ok) {
                            throw new Error('Invalid response');
                        }
                        return response.blob();
                    })
                    .then(blob => {
                        if (blob.size > 0) {
                            const a = document.createElement('a');
                            a.href = window.URL.createObjectURL(blob);
                            a.download = 'insights.csv';
                            document.body.appendChild(a);
                            a.click();
                            a.remove();
                        }
                    })
                    .catch(error => {
                        setResponseText(error.message);
                    })
                    .finally(() => {
                        setProcessing(false);
                    });
            };

            const TemplatesToProcess = () => {

                const removeMlTemplate = template => {
                    setMlTemplates(mlTemplates.filter(mlTemplate => mlTemplate !== template));
                }

                if (!mlTemplates.length) {
                    return ( <span>No templates selected</span> );
                }

                return (
                    <span>
                        {mlTemplates.map((template, key) => {
                            return (
                                <div key={key} style={{ display: "inline-flex", alignItems: "center" }}>
                                     <span key={key}>
                                        {template.name}
                                    </span>
                                    <IoMdClose
                                        style={{ marginLeft: "3px", marginRight: "15px", cursor: "pointer" }}
                                        onClick={() => removeMlTemplate(template)}
                                    />
                                </div>
                            );
                        })}
                        {mlTemplates.length ? (
                            <a href={'#'}
                               style={{marginLeft: '10px'}}
                               onClick={() => setMlTemplates([])}>
                                Clear
                            </a>
                        ) : null}
                        {responseText.length ? <div>{responseText}</div> : null}
                    </span>
                );
            };

            let buttonText = '';
            if (downloadCSV) {
               buttonText = processing ? 'Creating CSV...' : 'Create CSV File';
            } else {
                buttonText = processing ? 'Sending CSV...' : 'Send CSV for Processing';
            }

            return (
                <div>
                    <h4 style={{marginBottom: '5px'}}>Templates</h4>
                    <span>
                        <TemplatesToProcess />
                    </span>
                    <div>
                        <h4 style={{marginBottom: '5px'}}>Options</h4>
                        <div>
                            <select value={periodLength}
                                    disabled={processing}
                                    onChange={(event) => setPeriodLength(parseInt(event.target.value))}>
                                <option value="1440">24hrs</option>
                                <option value="720">12hrs</option>
                                <option value="360">6hrs</option>
                                <option value="60">1hr</option>
                                <option value="30">30min</option>
                                <option value="15">15min</option>
                            </select>
                            <span style={{marginLeft: '5px'}}>period</span>
                            <input value={liveNumDays}
                                   disabled={processing}
                                   onChange={(event) => {
                                       const val = parseInt(event.target.value);
                                       setLiveNumDays(val)
                                       numDays.current = val;
                                   }}
                                   style={{marginLeft: '10px' ,width: '50px'}} />
                            <span style={{marginLeft: '5px'}}>days</span>
                            <input type='checkbox'
                                   disabled={processing}
                                   style={{marginLeft: '10px'}}
                                   checked={downloadCSV}
                                   onChange={() => setDownloadCSV(!downloadCSV)}/>
                            Download CSV
                        </div>
                        <button onClick={() => processInsights()}
                                disabled={!mlTemplates.length || processing}
                                style={{marginTop: '10px'}}>
                            {buttonText}
                        </button>
                    </div>

                </div>
            );
        };

        const PublishMessage = () => {

            const publishMessage = () => {

                fetch(process.env.REACT_APP_BRAIN_HOST + '/publishMessage',
                    {
                        headers: new Headers({
                            'Heart-Secret-Key': process.env.REACT_APP_HEART_SECRET_KEY,
                            'Access-Control-Expose-Headers': 'Authorization',
                        })
                    }
                )
                    .then(response => {
                        console.log('publishMessage response', response);
                    });
            };

            return (
                <div>
                    <h4 style={{marginBottom: '5px'}}>Testing</h4>
                    <button onClick={() => publishMessage()}>
                        Publish message to RabbitMQ
                    </button>
                </div>
            );
        };

        const ActionSummary = () => {

            const generateActionSummary = () => {
                fetch(process.env.REACT_APP_BRAIN_HOST + '/generateActionSummary?_partition=' + user._partition,
                    {headers: new Headers({
                            'Heart-Secret-Key': process.env.REACT_APP_HEART_SECRET_KEY,
                        })}
                )
                    .then(response => response.json())
                    .then(
                        result => {
                            console.log('r', result);
                        },
                        error => {
                            console.log('e', error);
                        }
                    );
            };

            return (
                <div style={{marginTop: '20px'}}>
                    <h2>Action Summary</h2>
                    <button onClick={() => generateActionSummary()}>Generate Action Summary</button>
                    <div>
                        <span>No summary for today</span>
                    </div>
                </div>
            );
        };

        const EntryTemplates = () => {

            if (!entryTemplates.length) {
                return (
                    <div>
                        <h3>Templates</h3>
                        <span>Getting templates...</span>
                    </div>
                );
            }

            const addToMlTemplates = template => {
                setMlTemplates([...mlTemplates, template]);
            };

            const TemplatesList = ({templates}) => {
                return (
                    <div>
                        {templates.map((template, key) => {
                            if (!viewAllObjects && key >= 10) {
                                return null;
                            }
                            const lastUsedDate = new Date(template._last_used_date);
                            const _id = base64ToGuid(template._id);
                            const idShort = _id.substring(0, _id.indexOf('-'));
                            return (
                                <div key={key} style={{clear: 'both', marginTop: '5px'}}>
                                    <div style={{width: 50, position: 'relative', float: 'left'}}>
                                        {!template._starts_entry_template ? (
                                            <a href='#!' onClick={() => addToMlTemplates(template)}>Add</a>
                                        ) : <span>&nbsp;</span>}
                                    </div>
                                    <div style={{width: 100, position: 'relative', float: 'left'}}>
                                        {idShort}
                                    </div>
                                    <div style={{width: 210, position: 'relative', float: 'left'}}>
                                        <Link to={'template/' + _id}>
                                            {template._starts_entry_template ? (
                                                <span>Start: </span>
                                            ) : null}
                                            {template.name}
                                        </Link>
                                    </div>

                                    <div style={{width: 150, float: 'left'}}>{DateTime.getPlainTextDate(lastUsedDate)}</div>
                                    <div>{DateTime.getPlainTextTime(lastUsedDate)}</div>
                                </div>
                            );
                        })}
                    </div>
                );
            };

            let templates = [...entryTemplates];
            templates.sort((a, b) => a.name.localeCompare(b.name));

            const templatesByGroup = templates.reduce((prev, template) => {
                if (prev[template.category] === undefined) {
                    prev[template.category] = [];
                }
                prev[template.category].push(template);
                return prev;
            }, {});

            return (
                <div>
                    <h2>Templates ({entryTemplates.length})</h2>
                    {!viewAllObjects ? (
                        <button onClick={() => setViewAllObjects(true)}>
                            View All Objects
                        </button>
                    ) : null}
                    {Object.keys(templatesByGroup)
                        .sort((a, b) => a.localeCompare(b))
                        .map((category, key) => {
                        return (
                            <div key={key}>
                                <h4>{category}</h4>
                                <TemplatesList templates={templatesByGroup[category]} />
                            </div>
                        );
                    })}
                </div>
            );
        };

        const EntryTags = () => {

            if (!entryTags.length) {
                return (
                    <div>
                        <h3>Tags</h3>
                        <span>Getting tags...</span>
                    </div>
                );
            }

            const TagsList = ({tag}) => {

                const createdDate = new Date(tag._created_date);
                const _id = base64ToGuid(tag._id);
                const idShort = _id.substring(0, _id.indexOf('-'));

                return (
                    <div style={{clear: 'both', marginTop: '5px'}}>
                        <div style={{width: 100, position: 'relative', float: 'left'}}>
                            {idShort}
                        </div>
                        <div style={{float: 'left', width: '300px'}}>
                            {tag.name}
                        </div>
                        <div style={{float: 'left', width: '60px'}}>
                            <span>{tag.hidden ? 'Hidden' : '...'}</span>
                        </div>
                        <div style={{width: 150, float: 'left'}}>{DateTime.getPlainTextDate(createdDate)}</div>
                        <div style={{width: 80, float: 'left'}}>{DateTime.getPlainTextTime(createdDate)}</div>
                        <div>{JSON.stringify(tag.metadata)}</div>
                    </div>
                );
            };

            let tags = [...entryTags];
            tags = tags.sort((a, b) => {
                return (a.name > b.name ? 1 : -1);
            });

            return (
                <div>
                    <h2>Tags ({tags.length})</h2>
                    {tags.map((tag, key) => {
                        if (!viewAllObjects && key >= 5) {
                            return null;
                        }
                        return (
                            <div key={key}>
                                <TagsList tag={tag} />
                            </div>
                        );
                    })}
                </div>
            );
        };

        const Migration = () => {
          const [migrationStats, setMigrationStats] = useState(null);
          const [liveNumEntriesToImport, setLiveNumEntriesToImport] = useState(1);

          useEffect(() => {
            getMigrationStats().then();
          }, []);

          const getMigrationStats = async () => {
            // console.log('getMigrationStats');

            const response = await fetch(process.env.REACT_APP_BRAIN_HOST + '/user/' + user._id + '/migration/stats',{
              credentials: "include",
            });
            // console.log('getMigrationStats response', response);

            if (!response.ok) {
              console.error('Error getting migration stats:', response.statusText);
              return;
            }

            const myStats = await response.json();
            // console.log('migrationStats', myStats);
            setMigrationStats(myStats);
          };

          const importTags = async () => {
            console.log('importTags');

            const confirmed = window.confirm("Are you sure you want to import " + migrationStats.numEntryTags + " entry tags?")
            if (!confirmed) {
              return;
            }

            const response = await fetch(process.env.REACT_APP_BRAIN_HOST + '/user/' + user._id + '/migration/importEntryTags',{
              credentials: "include",
            });

            if (!response.ok) {
              console.error('Error importing entry tags:', response.statusText);
              return;
            }

            console.log('Imported entry tags');
          };

          const importTemplates = async () => {
            console.log('importTemplates');

            const confirmed = window.confirm("Are you sure you want to import " + migrationStats.numEntryTemplates + " entry templates?")
            if (!confirmed) {
              return;
            }

            const response = await fetch(process.env.REACT_APP_BRAIN_HOST + '/user/' + user._id + '/migration/importEntryTemplates',{
              credentials: "include",
            });

            if (!response.ok) {
              console.error('Error importing entry templates:', response.statusText);
              return;
            }

            console.log('Imported entry templates');
          };

          const importEntries = async () => {
            console.log('importEntries');

            const confirmed = window.confirm("Are you sure you want to import " + liveNumEntriesToImport + " entries?")
            if (!confirmed) {
              return;
            }

            const response = await fetch(process.env.REACT_APP_BRAIN_HOST + '/user/' + user._id + '/migration/importEntries'
              + '?num_entries_to_import=' + liveNumEntriesToImport,{
              credentials: "include",
            });

            if (!response.ok) {
              console.error('Error importing entries:', response.statusText);
              return;
            }

            console.log('Imported entries');

            // Refresh the stats
            await getMigrationStats();
          };

          return (
            <div>
              <div style={{marginTop: '20px', fontSize: '26px'}}>Migration</div>
              <div style={{marginTop: '10px'}}>
                <div style={{fontSize: '22px'}}>Mongo User</div>
                {user.mongo_user ?
                  <div>
                    User ID: {user.mongo_user._id}<br />
                    Partition: {user.mongo_user._partition}
                  </div>
                   : "No Connected Mongo User Record"}
              </div>
              <div style={{marginTop: '20px'}}>
                <div style={{fontSize: '22px'}}>Documents remaining to migrate</div>
                {migrationStats ?
                  <div>
                    Remaining Entries: {migrationStats.numEntries}<br />
                    Remaining EntryTemplates: {migrationStats.numEntryTemplates}<br />
                    Remaining EntryTags: {migrationStats.numEntryTags}<br />
                  </div>
                : "Loading..."}
              </div>
              {user.mongo_user && migrationStats ? (
                <div style={{marginTop: '20px'}}>
                  <div style={{fontSize: '22px'}}>Do Imports</div>
                  {migrationStats.numEntryTags > 0 ?
                    <div onClick={importTags}>Import Tags</div>
                    : <div>All tags imported</div>}
                  {migrationStats.numEntryTemplates > 0 ?
                    <div onClick={importTemplates}>Import Templates</div>
                    : <div>All templates imported</div>}
                  {migrationStats.numEntryTemplates === 0 && migrationStats.numEntryTags === 0 ? (
                      migrationStats.numEntries > 0 ? (
                        <div>
                          <div onClick={importEntries}>Import Entries</div>
                          <input type={'text'}
                                 value={liveNumEntriesToImport}
                                 onChange={(e) => setLiveNumEntriesToImport(e.target.value)}
                                 />
                        </div>
                      ) : <div>All entries imported</div>
                    ) : <div>Templates and tags must be imported before importing entries</div>}
                </div>
              ) : null}
            </div>
          )
        }

        return (
            <div>
                <div style={{clear: 'both'}}>
                  <Migration />
                    {/*<div>*/}
                    {/*    <AppInfo />*/}
                    {/*</div>*/}
                    {/*<div style={{marginTop: '10px'}}>*/}
                    {/*    <ProcessInsights />*/}
                    {/*</div>*/}
                    {/*<div style={{marginTop: '10px'}}>*/}
                    {/*    <PublishMessage />*/}
                    {/*</div>*/}
                    {/*<ActionSummary />*/}
                    {/*<EntryTemplates />*/}
                    {/*<EntryTags />*/}
                </div>
            </div>

        );
    };

    return (
        <div style={{padding: '20px', marginBottom: '20px'}}>
          <div>
            <Link to={`/`}>Back to Dashboard</Link>
          </div>
          <span>Firestore User: {user ? user._id : 'Loading...'}</span>
          {user ? <Content /> : null}
        </div>
    );
}

export default User;
