import * as React from 'react';
import {useParams} from "react-router-dom";
import {useEffect, useState} from "react";

function Template() {
    const params = useParams()
    const [entryTemplate, setEntryTemplate] = useState([]);
    const [entries, setEntries] = useState([]);

    useEffect(() => {
        getEntryTemplate();
    }, []);

    useEffect(() => {
        getEntries();
    }, [entryTemplate]);

    const getEntryTemplate = () => {
        console.log('getEntryTemplate');

        fetch(process.env.REACT_APP_BRAIN_HOST + '/entryTemplate/' + params.entryTemplateId
             + '?_partition=user=' + params.userId,
            {headers: new Headers({
                    'Heart-Secret-Key': process.env.REACT_APP_HEART_SECRET_KEY,
                })}
        )
            .then(response => response.json())
            .then(
                result => {
                    console.log('r', result);
                    setEntryTemplate(result);
                },
                error => {
                    console.log('e', error);
                }
            );
    };

    const getEntries = () => {
        console.log('getEntries');
    };

    const EntryTemplate = () => {
        return (
            <div>
                <span>Template: {entryTemplate.name}</span>
            </div>
        );
    };

    const Entries = () => {
        const [viewAll, setViewAll] = useState(false);

        let myEntries = [...entries];
        myEntries = myEntries.sort((a, b) => {
            return (a.name > b.name ? 1 : -1);
        });
        if (!viewAll) {
            myEntries = myEntries.splice(0, 10);
        }

        return (
            <div>
                <h2>Templates</h2>
                {myEntries.map((entry, key) => {
                    const effectiveDate = new Date(entry.effectiveDate);
                    return (
                        <div key={key}>
                            <div style={{width: 250, position: 'relative', float: 'left'}}>{entry.name}</div>
                            <div style={{width: 800}}>{effectiveDate.toString()}</div>
                        </div>
                    );
                })}
                {!viewAll ? <button onClick={() => setViewAll(true)}>View All</button> : null}
            </div>
        );
    };

    return (
        <div>
            <span>User: {params.userId}</span>
            <div>
                {entryTemplate.length ? <EntryTemplate /> : <span>Getting template...</span>}
                {entries.length ? <Entries /> : <span>Getting entries...</span>}
            </div>
        </div>
    );
}

export default Template;
